@import "../../../Themes/global";

.wrapper {
  background: $color-white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 100%;
}

.wrapper.collapsed {
  & .collapse {
    transform: rotate(180deg);
  }

  & .content {
    display: none;
  }

  & .header {
    border-bottom: none;
  }
}

.header {
  border-bottom: 1px solid $color-brand-light;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.title {
  @extend %text-subhead;
  color: $color-brand-dark;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 6px 8px 16px;
}

.collapse {
  margin-left: 12px;
  transition: transform ease 0.2s;
  cursor: pointer;
}
