@import "../../Themes/global";

.input {
  @extend %text-footnote;
  color: $color-brand-dark;
  padding: 11px 16px;
  box-sizing: border-box;

  border: 1px solid $color-brand-light;
  border-radius: 4px;
  background: $color-brand-light;

  outline: none;

  transition: all 0.3s ease;

  &:active,
  &:focus {
    background: rgba($color-brand-main, 0.1);
    color: $color-brand-main;
    border-color: $color-brand-main;
  }

  &::placeholder {
    color: $color-black-40;
  }
}

.item {
  @extend %text-footnote;
  color: $color-brand-dark;
  padding: 11px 16px;
  box-sizing: border-box;
  outline: none;
  background: $color-brand-light;

  transition: all 0.3s ease;

  border: 1px solid $color-brand-light;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.item.highlighted {
  background: rgba($color-brand-main, 0.1);
  color: $color-brand-main;
}

.item.secondary {
  color: $color-brand-dark;

  &.highlighted {
    background: rgba($color-brand-accent, 0.1);
    color: $color-brand-accent;
  }
}

.item.small {
  padding: 5px 8px;
}
