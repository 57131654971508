@import "../../Themes/global";

.container {
  display: flex;
  flex-direction: column;

  padding-bottom: 21px;

  &:not(:last-child) {
    margin-bottom: 24px;
    border-bottom: 1px solid #e4e4e4;
  }
}

@media only screen and (min-width: $media-breakpoint-largescreen-min) {
  .container {
    display: flex;
    flex-direction: column;
    padding: 0 200px 21px;
  
    &:not(:last-child) {
      margin-bottom: 24px;
      border-bottom: 1px solid #e4e4e4;
    }
  }
}

.title {
  @extend %text-button;
  width: 100%;
  margin-bottom: 14px;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 21px;
}

.item {
}
