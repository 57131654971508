@import "../../Themes/global";

.page {
  display: flex;
  flex-direction: column;
}

.fillHeight {
  height: 100%;
}
