@import "../../Themes/global";

.wrapper {
  position: relative;
  width: fit-content;
}

.wrapper.labeled {
  padding-top: 20px;
}

.wrapper.error {
  & .input {
    background: rgba(red, 0.1);
    color: red;
    border-color: red;
  }
}

.wrapper.disabled {
  & .input {
    opacity: 0.5;
    pointer-events: none;
  }
}

.wrapper.fullWidth {
  width: 100%;

  & .input {
    width: 100%;
  }
}

.wrapper.iconed {
  & .input {
    padding-left: 40px;
  }

  & .icon {
    position: absolute;
    left: 8px;
    bottom: 10px;
  }
}

.wrapper.withSuffix {
  & .input {
    padding-right: 40px;
  }

  & .iconSuffix {
    position: absolute;
    right: 8px;
    bottom: 10px;
    pointer-events: none;
  }
}

.wrapper.small {
  & .input {
    padding: 5px 8px;
  }

  &.withSuffix {
    & .input {
      padding-right: 32px;
    }

    & .iconSuffix {
      bottom: 4px;
    }
  }

  &.iconed {
    & .input {
      padding-left: 32px;
    }

    & .icon {
      bottom: 4px;
    }
  }
}

.wrapper.secondary {
  & .input {
    border: 1px solid $color-brand-light;
    background: $color-brand-light;

    &:active,
    &:focus {
      background: rgba($color-brand-accent, 0.1);
      color: $color-brand-accent;
      border-color: $color-brand-accent;
    }
  }
}

.label {
  position: absolute;
  left: 0;
  top: 0;

  @extend %text-caption;
  color: $color-gray;
}

.input {
  @extend %text-footnote;
  color: $color-brand-dark;
  padding: 11px 16px;
  box-sizing: border-box;

  border: 1px solid $color-brand-light;
  border-radius: 4px;
  background: $color-brand-light;

  outline: none;

  transition: all 0.3s ease;

  &:active,
  &:focus {
    background: rgba($color-brand-main, 0.1);
    color: $color-brand-main;
    border-color: $color-brand-main;
  }

  &::placeholder {
    @extend %text-footnote;
    color: $color-black-40;
  }
}
