@import "../../Themes/global";

.wrapper {
  height: $height-header;
  width: 100%;
  z-index: $z-header;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  
  background: $color-white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);

  @media screen and (min-width: $media-breakpoint-desktop-min) {
    padding: 0 32px;
  }
}

.back {
  margin-right: 12px;
}

.left {
  display: flex;
  align-items: center;
}

.wrapper.relative {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
}

@media only screen and (min-width: $media-breakpoint-largescreen-min) {
  .actions {
    padding-right: 200px;
  }
}

.actions {
  padding: 0 10px;
}

.textWrapper {
  display: inline-flex;
  align-items: baseline;
}

.title {
  @extend %text-headline-2;
}

.info {
  @extend %text-subhead;
  padding-left: 20px;
}
