@import "../../Themes/global";

$avatarSize: 42px;

.menuComponent {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: $width-menu;
  padding: 14px;

  position: relative;

  overflow-x: hidden;
  z-index: 1;
  background-color: $color-brand-dark;

  font-family: $font-primary;
}

.menuLink {
  color: #fff;
  border-radius: 4px;
  padding: 6px 4px 6px 34px;
  text-decoration: none;
  font-size: 14px;

  &.activeLink {
    background-color: rgba($color: #fff, $alpha: 0.2);
  }
}

.logo {
  width: 150px;
  height: 33px;
  cursor: pointer;
  align-self: center;
  padding-top: 14px;
  padding-bottom: 94px;
}

.dropdownMenu {
  position: absolute;
  bottom: 100%;
  width: 130px;
  min-height: 40px;
  padding: 5px 0px;
  border-radius: 3px;
  background: #fff;
  text-align: center;
  z-index: 10;

  & > a {
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    color: $color-gray;
    display: block;
    padding: 7px 13px;
    cursor: pointer;
    border-bottom: solid 1px $color-secondGray;

    &:not(:first-child) {
      margin-top: 6px;
    }

    &:hover {
      background-color: rgba($color-brand-main, 0.15);
    }

    &:last-child {
      border: none;
    }
  }

  & :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 12%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: white;
    border-bottom: 0;
    margin-left: -7px;
    margin-bottom: -7px;
  }
}

.userContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  padding: 6px 21px 21px 21px;
  cursor: pointer;
}

.userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .userImage {
    width: $avatarSize;
    height: $avatarSize;
    min-width: $avatarSize;
    background-color: $color-brand-accent;
    text-align: center;
    color: white;
    border-radius: 50%;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
