@import "../../Themes/global";

.container {
  @extend %text-subbutton;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 260px;
  background-color: $color-white;
  padding: 0;
  border-radius: 4px;
  height: 24px;
  box-sizing: border-box;
  color: $color-brand-accent;
}

.container.sub {
  border: none;
  height: 24px;

  & .button {
    height: 100%;
    border-radius: 4px;
    color: $color-gray;
    background-color: $color-brand-light;
    border: none;

    &.active {
      color: $color-white;
      background-color: $color-brand-accent;
      border: 1px solid $color-brand-accent;
    }

    &:first-child {
      margin-right: 4px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }
}

.button {
  align-items: center;
  color: $color-brand-accent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  width: 50%;
  box-sizing: border-box;

  border: 1px solid $color-brand-accent;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.button.active {
  cursor: default;
  color: $color-white;
  background-color: $color-brand-accent;
}
