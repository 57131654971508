@import "../../Themes/global";

.container {
  background: #fafafa;
  border-radius: 4px;
  margin-bottom: 30px;
  position: relative;
  height: 225px;
  max-width: 200px;
  display: block;
  cursor: pointer;
  text-decoration: none;
}

.image {
  border-radius: 4px;
  object-fit: cover;
  height: 175px;
  width: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.infoContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
}

.textNum {
  @extend %text-caption;
  color: $color-gray;
}

.textTitle {
  @extend %text-subhead;
  color: $color-brand-dark;

  & span:not(:last-child) {
    margin-right: 6px;
  }
}
