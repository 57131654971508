.title {
  text-align: left;
  margin-block-start: 1.2em;
  margin-block-end: 0.4em;
  color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
}

.chartContainer {
  width: 600px;
  height: 400px;
}
