@import "../../Themes/global";

$height-title: 35px;
$padding-container: 10px;

.container {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: $padding-container;
}

.scrollList {
  position: absolute;
  left: $padding-container;
  margin-top: $height-title;
  max-height: calc(100% - #{$height-title} - (#{$padding-container} * 2));
  width: calc(100% - (#{$padding-container} * 2));
  flex-wrap: nowrap;
  overflow-y: auto;
}

.title {
  padding: 5px 5px 10px 5px;
  font-size: 16px;
  height: $height-title;
  
  .subtitle {
    font-size: 0.8em;
  }
}

.infoText {
  font-size: 0.8em;
  padding-left: 10px;
}
