.wrapper {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
}

.membersList {
  width: 100%;
}

.searchWrapper {
  width: 100%;
  padding: 10px 20px;
}
