.background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
}

.container {
  position: fixed;
  top: 10%;
  left: 20%;
  background-color: white;
  height: 80%;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  height: 46px;
  background-color: #220ea4;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.closeButton {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 20px;
  cursor:pointer;
  outline:none;
}

.form {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
}

.titleInput {
  border: 5px solid red;
}

.timeInput {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*align-items: center;*/
}

.submitButtons {
  display: flex;
  margin-top: auto;
  justify-content: space-evenly;
  margin-left: 19%;
  margin-right: 20%;
  margin-bottom: 6px;
}

.blueButton {
  background-color: #220ea4;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  height: 38px;
  width: 80px;
  display: flex;
  justify-content: space-around;
  cursor:pointer;
  outline:none;
}

.cancelButton {
  background: transparent;
  border: none;
  cursor: pointer;
  outline:none;
  font-size: 14px;
  text-decoration: underline;
}
