@import "../../../../Themes/global";

.wrapper {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  min-height: 32px;
}

.num {
  padding-right: 18px;
}

.value {
  @extend %text-subhead;
  color: $color-brand-dark;
}

.title {
  @extend %text-footnote;
  color: $color-brand-dark;
}
