@import "../../Themes/global";

.label {
  @extend %text-subhead;

  border: 1px solid $color-brand-light;
  border-radius: 4px;
  color: $color-gray;
  background-color: $color-white;
  padding: 1px 7px;
  text-align: center;
}

.label.small {
  @extend %text-caption;
  padding: 2px 4px;
}

.label.fail {
  color: $color-white;
  background-color: $color-red;
  border-color: $color-red;
}

.label.success {
  color: $color-white;
  background-color: $color-green;
  border-color: $color-green;
}
