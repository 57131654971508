@import "../../Themes/global";

.rangeSliderOuterWrapper {
  width: 100%;
  display: flex;
  padding: 6px 0;
  flex-direction: column;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.selectAll {
  align-self: flex-end;
  padding: 8px;

  & a {
    cursor: pointer;
    font-size: 12.4px;
    color: $color-fontGray;

    &:hover {
      color: $color-brand-accent;
    }
  }
}

.filterTitle {
  padding-top: 12px;
  padding-bottom: 8px;

  @extend %text-subhead;
  line-height: 25px;
  color: $color-brand-dark;
}

.rangeSlider {
  padding: 14px;
}

.inputRangeSlider {
  background: $color-brand-accent !important;
  border: none !important;
}

.inputRangeTracksActive {
  background: $color-brand-accent !important;
}
