@import "../../../../../Themes/global";

.wrapper {
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.wrapperSub {
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: none;

  & .title {
    font-size: 14px;
  }
}

.subfilter {
  padding-top: 6px;
}

.title {
  padding-top: 12px;
  padding-bottom: 8px;

  @extend %text-subhead;
  line-height: 25px;
  color: $color-brand-dark;
}

.autocompleteWrapper {
  width: 100%;
  padding: 8px 0 6px;
}
