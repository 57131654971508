@import "../../Themes/global";

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-brand-dark;
}

.loginContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: -10%;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $color-white;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 40px 32px;

  & > form {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.title {
  @extend %text-headline-1;
  text-align: center;
  margin-bottom: 9px;
}

.footer {
  @extend %text-caption;
  color: $color-white;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 36px;
  left: 0;
  right: 0;
}

.signalityLogo {
  height: auto;
  width: 80px;
  margin-left: 8px;
  z-index: 2;
}

.logoImage {
  height: auto;
  margin-bottom: 24px;
  width: 200px;
}

.text {
  @extend %text-footnote;
  color: $color-brand-dark;
  text-align: center;
  margin-bottom: 27px;
}

.formGroup {
  text-align: center;
  margin-bottom: 8px;
  &.noMargin {
    margin-bottom: 0;
  }

  input {
    display: block;
    font-family: "Source Sans Pro", sans-serif;
    width: 300px;
    padding: 10px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: $color-gray;
    background-color: #fafafa;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 2px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    box-shadow: none;

    &::placeholder {
      color: lighten($color-fontGray, 20%);
    }

    &:focus {
      outline: 0;
    }

    &.error {
      border: 1px solid red;
      color: red;
    }
  }
}

.getInTouch {
  @extend %text-caption;
  padding: 24px 0 0;
  text-align: center;
  color: $color-black-40;
}

.email {
  @extend %text-caption;
  appearance: none;
  color: $color-brand-dark;
  margin-left: 8px;

  &:hover {
    color: lighten($color-brand-dark, 20%);
  }
}
