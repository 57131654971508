.container {
  margin: 12px;
}

.row {
  border-bottom: 1px solid darkgray;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 8px 8px; 
  border-radius: 4px;
  min-height: 32px;
  text-align: left;

  .date {
    flex-basis: 13%;
  }
  .title {
    margin-left: 4px;
    flex-grow: 1;
    padding-bottom: 10px;

    a {
      font-size: 1.1em;
    }
    
    > div {
      font-size: 0.9em;
      width: 100%;
      display: flex;
      padding: 10px 0px 0px 0px;

      :nth-child(1) {
        min-width: 95px;
      }
    }
  }
  .teams {
    flex-basis: 20%;
    margin-left: 14px;
    min-width: 50px;;
  }

  .teamForm {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: 300px;
    flex-basis: 27%;
  
    :nth-child(1) {
      flex-grow: 4;
    }
  
    button {
      flex-grow: 1;
      max-width: 70px;
      margin-left: 20px;
    }
  }
}

