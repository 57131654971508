@import "../../Themes/global";

.button {
  @extend %text-button;

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 6px;
  width: 36px;
  height: 36px;
  box-sizing: border-box;

  border: none;
  border-radius: 4px;
  color: $color-white;
  background-color: transparent;

  outline: none;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button.small {
  padding: 0 6px;
}
