@import "../../../Themes/global";

.wrapper {
  @extend %text-caption;

  flex: 0 0 50%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 0 6px 0;

  cursor: pointer;

  &.checked {
    color: $color-brand-dark;
  }
}

.label {
  padding-left: 8px;
}

.radio {
  width: 22px;
  height: 22px;
  flex: 0 0 22px;
  border-radius: 50%;
  background: rgba(13, 0, 52, 0.05);
  position: relative;

  & input {
    display: none;
  }

  &.checked .outer {
    background: $color-brand-accent;
  }

  &.checked .inner {
    display: inline-block;
  }
}

.outer {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
}

.inner {
  display: none;
  position: absolute;
  background: white;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 7px;
  left: 7px;
  z-index: 1;
}
