$height-footer: 103px;
$height-header: 56px;
$height-content: calc(100vh - #{$height-header});

$width-menu: 210px;
$width-right-panel: 270px;
$width-right-panel-max: 400px;

// Variables for responsiveness
$media-breakpoint-phone-min: 320px;
$media-breakpoint-tablet-min: 1024px;
$media-breakpoint-desktop-min: 1224px;
$media-breakpoint-largescreen-min: 1824px;

//new styling

$color-brand-main: #2a00ab;
$color-brand-accent: #f96332;
$color-brand-dark: #0d0034;
$color-brand-light: rgba(13, 0, 52, 0.05);

$color-body: #111111;

$color-gray: #444444;
$color-grayish: #e4e4e4;
$color-black-50: rgba(0, 0, 0, 0.5);
$color-black-40: rgba(0, 0, 0, 0.4);
$color-black-20: rgba(0, 0, 0, 0.2);

$color-white: #ffffff;
$color-white-70: rgba(255, 255, 255, 0.7);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-white-20: rgba(255, 255, 255, 0.2);

$color-red: #e52a2a;
$color-green: #6ebc89;

//legacy colors
$color-fontGray: #7b7b7b;
$color-secondGray: #fafafa;
$color-greenish: #69db97;

$font-primary: "Work Sans", sans-serif;

$z-header: 2;
$z-sidebar: 5;

%text-headline-1 {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 600;
  line-height: 37px;
  font-size: 26px;
  color: $color-brand-dark;
}

%text-headline-2 {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  font-size: 20px;
  color: $color-brand-dark;
}

%text-headline-3 {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  font-size: 18px;
  color: $color-brand-dark;
}

%text-button {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  font-size: 16px;
  color: $color-brand-dark;
}

%text-subbutton {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-size: 13px;
  color: $color-brand-dark;
}

%text-body {
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  color: $color-body;
}

%text-subhead {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: $color-brand-dark;
}

%text-footnote {
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: $color-brand-dark;
}

%text-caption {
  font-family: $font-primary;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  font-size: 12px;
  color: $color-brand-dark;
}
