@font-face {
  font-family: "ProximaNovaRegular";
  src: local("ProximaNovaRegular"),
    url("/assets/fonts/ProximaNova/ProximaNova-Regular.otf") format("opentype");
}
@font-face {
  font-family: "ProximaNovaBold";
  src: local("ProximaNovaBold"),
    url("/assets/fonts/ProximaNova/ProximaNova-Bold.otf") format("opentype");
}
body {
  background: #fafafa;
  font-family: "ProximaNovaRegular";
  padding: 0px;
  margin: 0px;
}
html {
  overflow-y: scroll;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
