@import "../../Themes/global";

.container {
  @extend %text-caption;
  background-color: $color-white;
  background-clip: padding-box;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid $color-white;
  cursor: pointer;

  & .title {
    color: $color-gray;
    margin-block-start: 0.1em;
    margin-block-end: 0.2em;
  }

  & .time {
    @extend %text-footnote;
    color: $color-black-40;
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
  }

  &:hover {
    border-color: $color-brand-main;
  }
}

.container.even {
  background-color: $color-brand-light;
  border-color: $color-brand-light;

  &:hover {
    border-color: $color-brand-main;
  }
}

.container.active {
  background-color: rgba($color-brand-main, 0.15);
  border-color: rgba($color-brand-main, 0.15);

  & .title {
    color: $color-brand-main;
  }

  & .time {
    color: $color-brand-main;
  }

  &:hover {
    border-color: $color-brand-main;
  }
}
