@import "../../../Themes/global";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .subtitle {
    @extend %text-headline-3;
    align-self: flex-start;
  }

  .value {
    @extend %text-headline-1;
    align-self: center;
  }
}
