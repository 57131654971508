@import "../../Themes/global";

.button {
  @extend %text-button;

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;

  border: 1px solid $color-brand-main;
  border-radius: 4px;
  color: $color-white;
  background-color: $color-brand-main;

  outline: none;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: linear-gradient(0deg, $color-black-40, $color-black-40),
      $color-brand-main;
  }
}

.button.fullWidth {
  width: 100%;
}

.button.small {
  @extend %text-caption;
  color: $color-white;
  padding: 6px 20px;
}

.button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button.clear {
  background: transparent;
  border: none;
  cursor: pointer;
  color: $color-fontGray;
  outline: none;
  text-decoration: underline;
}

.button.secondary {
  color: $color-brand-main;
  background: rgba($color-brand-main, 0.1);
  border-color: rgba($color-brand-main, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: darken(rgba($color-brand-main, 0.1), 20%);
  }
}

.button.remove {
  color: $color-red;
  background: $color-brand-light;
  border-color: $color-brand-light;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: darken($color-brand-light, 20%);
  }
}
