.container {
  width: 100%;

  .row {
    border-bottom: 1px solid darkgray;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 8px 8px; 
    border-radius: 4px;
    min-height: 32px;
    text-align: left;
    font-weight: bolder;
  
    .date {
      flex-basis: 13%;
    }
    .title {
      margin-left: 4px;
      flex-grow: 1;
    }
    .teams {
      flex-basis: 20%;
      margin-left: 14px;
      min-width: 50px;;
    }
    .form {
      min-width: 300px;
      flex-basis: 27%;
    }
  }
}
