@import "./Themes/global";

.container {
  display: grid;
  height: 100vh;

  grid-template-columns: $width-menu auto;
  grid-template-rows: $height-header $height-content;
  grid-template-areas:
    "menu header"
    "menu content";
}

.container.withSidebar {
  grid-template-columns: $width-menu auto $width-right-panel;
  grid-template-areas:
    "menu header sidebar"
    "menu content sidebar";
}

/** Small Desktop */
@media only screen and (min-width: $media-breakpoint-desktop-min) {
  .container {
    display: grid;
    height: 100vh;

    grid-template-columns: $width-menu auto;
    grid-template-rows: $height-header $height-content;
    grid-template-areas:
      "menu header"
      "menu content";
  }

  .container.withSidebar {
    grid-template-columns: $width-menu auto $width-right-panel;
    grid-template-areas:
      "menu header sidebar"
      "menu content sidebar";
  }
}

/** Large screen */
@media only screen and (min-width: $media-breakpoint-largescreen-min) {
  .container {
    display: grid;
    height: 100vh;

    grid-template-columns: $width-menu auto;
    grid-template-rows: $height-header $height-content;
    grid-template-areas:
      "menu header"
      "menu content";
  }

  .container.withSidebar {
    grid-template-columns: $width-menu auto $width-right-panel-max;
    grid-template-areas:
      "menu header sidebar"
      "menu content sidebar";
  }
}

.header {
  grid-area: header;
}

.menu {
  grid-area: menu;
}

.sidebar {
  grid-area: sidebar;
}

.sidebar.hidden {
  display: none;
}

.content {
  grid-area: content;
  width: 100%;
  overflow: auto;
}
