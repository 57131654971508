@import "../../../../Themes/global";

$slider-height: 5px;

.container {
  position: relative;
  height: 10px;
  width: 100%;
}

.container.disabled {
  & .outerCss,
  & .innerCss,
  & .default {
    cursor: default;
    pointer-events: none;
  }
}

.container:not(.disabled):hover {
  & .circle {
    height: 13px;
    width: 13px;
    margin-top: -4px;
  }
}

.outerCss {
  height: $slider-height;
  width: 100%;
  vertical-align: middle;
  cursor: pointer;
  background: $color-white-20;
  border-radius: 4px;
}

.innerCss {
  position: absolute;
  left: 0;
  top: 0;
  height: $slider-height;
  border-radius: 4px;
  background-color: $color-brand-accent;
  cursor: pointer;
}

.buffered {
  position: absolute;
  left: 0;
  top: 0;
  height: $slider-height;
  border-radius: 4px;
  background-color: $color-white-50;
  cursor: pointer;
}

.circle {
  height: 5px;
  width: 5px;
  margin-top: 0;
  border-radius: 50%;
  border: solid 2px $color-brand-accent;
  background-color: $color-brand-accent;
  position: absolute;
  top: 0 !important;
  margin-left: -5px;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: 0 !important;
  margin-left: -25px;
  margin-top: -23px;
  padding: 3px 4px;
  border-radius: 3px;
  font-size: 0.7em;
  color: white;
  background-color: $color-black-50;
  pointer-events: none;
}
