@import "../../Themes/global";

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background-color: white;
  border-radius: 8px;
  max-height: 80%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
}

.inner {
  overflow: auto;
  background: #fff;
}

.inner.noScroll {
  overflow: initial;
  min-height: 430px;
}

.inner.padded {
  padding: 32px;
}

.container.small {
  width: 400px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 0;
  min-height: 60px;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.title {
  @extend %text-headline-2;
  background-color: $color-white;
  color: $color-brand-dark;
  padding: 20px 20px 0;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 32px 40px;
  min-height: 109px;
  background: $color-white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.button {
  margin-left: 12px;
}
