@import "../../Themes/global";

$containerBorderRadius: 12px;
$containerHeight: 24px;
$buttonBorderRadius: 50%;
$buttonHeight: 28px;
$containerWidth: $buttonHeight * 2;
$activeButtonBackgroundColor: $color-brand-accent;

.container {
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: center;
  width: $containerWidth;
  background-color: $color-brand-light;
  font-size: 0.9em;
  margin-left: 5px;
  margin-right: 10px;
  border-radius: $containerBorderRadius;
  height: $containerHeight;
}

.container.selected {
  background-color: rgba($color-brand-accent, 0.4);
}

.button {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: transparent;
  width: 50%;

  height: $buttonHeight;
  border-radius: $buttonBorderRadius;

  &.active {
    background-color: $activeButtonBackgroundColor;
  }
}
