@import "../../Themes/global";

.wrapper {
  display: flex;
  flex-direction: column;
  width: $width-right-panel;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: -1px 0px 0px #e4e4e4;
  height: 100%;
  width: 100%;

  z-index: $z-sidebar;
}

.header {
  display: flex;
  align-items: center;
  height: $height-header;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
}

.title {
  @extend %text-headline-3;
  color: $color-brand-dark;
  padding-left: 12px;
}

.content {
  height: calc(100vh - #{$height-header});
  overflow: auto;
  box-sizing: border-box;
  padding: 24px 18px;
}
