@import "../../Themes/global";

.betaTag {
  position: absolute;
  height: 13px;
  width: 27px;
  padding-top: 2px;

  border-radius: 4px;
  z-index: 10;

  font-family: $font-primary;
  font-weight: bold;
  font-size: 9px;
  text-align: center;
  color: white;

  &:after {
    content: "beta";
  }
}
