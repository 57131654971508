@import "../../../../Themes/global";

.wrapper {
  display: flex;
  align-items: center;
  background: $color-brand-light;
  border-radius: 4px;
  padding: 0 8px;
  min-height: 32px;
}

.wrapper.even {
  background: $color-white;
}

.title {
  @extend %text-footnote;
  color: $color-brand-dark;
}
