@import "../../../Themes/global";

.title {
  text-align: left;
  margin-block-start: 1.2em;
  margin-block-end: 0.4em;
  color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
}

.verticalBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.blueButton {
  background-color: #220ea4;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  height: 38px;
  width: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.message {
  color: lightgray;
  text-align: center;
  margin-top: 50px;
}

.dashboardContainer {
  background: $color-secondGray;
  display: flex;
  flex-direction: column;
}
