@import "../../../Themes/global";

.analysisContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  height: 100%;
}

.title {
  text-align: center;
  padding: 24px 32px 24px 18px;
  font-family: "ProximaNovaRegular";
  font-size: 20px;
  font-weight: 600;
}

.eventFilter {
  flex-shrink: 0;
  min-height: 0;
}

.playlist {
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 0;
  height: 50%;
}

.menuWrapper {
  display: flex;
}

.actions {
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  height: 100%;
}

.mode {
  display: flex;
  align-items: center;
  padding-top: 9px;
  position: relative;
}

.reportLink {
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin: 0;
  padding: 0;
  width: 27px;
  font-size: 24px;
  color: $color-gray;
}

.rangeSlider {
  align-self: left;
  max-width: 70%;
}

// .mapOverlay {
//   pointer-events: none;
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   padding: 20px;
//   height: 80%;
// }
