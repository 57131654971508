@import "../../Themes/global";

.dashboardContainer {
  background: $color-secondGray;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  align-self: center;
  max-width: 1100px;
  width: 80%;
}

.title {
  text-align: left;
  margin-block-start: 1.2em;
  margin-block-end: 0.4em;
  color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
}
