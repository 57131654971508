@import '../../Themes/global';

.container {
  position: relative;
  height: 100%;
  text-align: center;

  & > svg {
    max-height: calc(100% - 34px);
    max-width: 100%;
  }
}

.gridButtonWrapper {
  width: 100%;
  padding: 0 15px 0;
  display: flex;
  justify-content: flex-end;
  align-content: center;

  span {
    @extend %text-subbutton;
    display: inline-flex;
    align-self: center;
    padding-right: 7px;
  }
}

.pass {
  &:hover {
    cursor: pointer;
  }
}
