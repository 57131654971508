.container {
  display: flex;
  justify-content: center;
  flex: 1;
}

.image {
  height: 75px;
  width: 75px;
}
