@import "../../../../Themes/global";

.container {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.buttonHide {
  color: $color-fontGray;
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
}

.filterCard {
  position: absolute;
  top: 32px;
  left: -32px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border: 1px solid gray;
  border-right: none;
  cursor: pointer;

  &:hover {
    background: #ddd;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subFilter {
  display: flex;
  justify-content: center;
  width: 100%;
}

.smallToggle {
  @extend %text-footnote;
  display: flex;
  align-items: center;
  color: $color-brand-dark;
  margin: 0 0 16px;
}
