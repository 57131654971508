.wrapper {
  padding: 0 20px;
  display: flex;
  align-items: center;
  background: #fff;
  cursor: pointer;
}

.wrapper:hover {
  background: #f9f9f9;
}

.num {
  font-size: 16px;
  font-weight: 600;
  width: 40px;
}

.title {
  font-size: 16px;
}
