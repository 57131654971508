@import "../../../../Themes/global";

.container {
  font-family: $font-primary;
  font-size: 0.85em;
  display: inline-flex;
  align-items: center;
  padding-bottom: 2px;
  padding-left: 6px;
}

.timeBox {
  display: flex;
  width: 10em;
}

.timeElement {
  width: 4em;
  text-align: center;
}
