@import "../../Themes/global";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  min-width: 570px;
}

.container {
  width: 100%;
  color: white;
  background: black;
  box-sizing: border-box;
  overflow: hidden;
}

.wrapper.fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding-top: 48px;
  background-color: black;
  height: 100%;
  width: 100%;
}

.container video {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
}

.container canvas {
  width: 100%;
}

.controls {
  width: 100%;
  background: black;
  padding: 10px;
}

.wrapper.fullscreen .controlsTop {
  position: fixed;
  top: 0;
  right: 10px;
}

.wrapper.fullscreen .controls {
  position: fixed;
  bottom: 0;
  padding-bottom: 10px;
}

.buttonBar {
  margin: 0 0.5em;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.buttonBarLeft {
  display: flex;
  align-content: center;
}

.buttonBar img {
  width: 25px;
}

.buttonBar button {
  color: white;
  outline: none;
  border: 0 solid;
  background: transparent;
  fill: white;
  opacity: 0.9;
  vertical-align: middle;
  cursor: pointer;
  padding-left: 4px;
  padding-right: 4px;
}

.buttonBar button:hover {
  opacity: 1;
}

.buttonBar button.on {
  opacity: 1;
  color: $color-brand-accent;
}

.buttonBar select {
  @extend %text-footnote;
  font-family: $font-primary;
  width: 48px;
  border: none;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 4px;
  font-size: 0.6em;
  margin-right: 6px;

  &:focus {
    outline: none;
  }
}

.buttonBarRight {
  display: inline-flex;
  align-items: center;
}

.paddingInput {
  @extend %text-footnote;
  margin-right: 10px;
  color: white;
  font-size: 0.6em;

  .inputSpan {
    position: relative;
    margin: 0 10px;

    &:after {
      color: black;
      content: 's';
      position: absolute;
      right: 14px;
      top: -4px;
    }
  }

  .barIcon {
    vertical-align: middle;
    font-size: 1.5em;
  }

  .rotatedIcon {
    transform: rotate(180deg);
  }

}
