@import "../../Themes/global";

.wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  
  &:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  }

  &.disabled {
    pointer-events: none;

    & .card {
      opacity: 0.6;
    }
  }

  &.loading {
    pointer-events: none;

    & .card {
      animation: blink 1s infinite alternate;
    }
  }
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 138px;
  width: 100%;
  box-sizing: border-box;
  padding: 28px 24px 24px;
  background-color: white;
  border-radius: 1px;
  text-decoration: none;
}

.footer {
  padding: 9px 9px 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $color-secondGray;
  border-top: solid rgba(0, 0, 0, 0.04) 1px;
}

.title {
  @extend %text-headline-2;
  text-align: center;
  color: $color-gray;
}

.time {
  @extend %text-caption;
  color: $color-black-50;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.actions {
  display: flex;
  align-self: flex-end;
}

.report {
  @extend %text-caption;
  color: $color-brand-main;

  display: flex;
  align-items: center;

  cursor: pointer;
  text-decoration: none;
  margin-right: 8px;

  & > span {
    margin-right: 8px;
  }
}

@keyframes blink {
  from {
    opacity: 0.6;
  }

  to {
    opacity: 1;
  }
}
