@import "../../Themes/global";

.container {
  background: white;
  border-radius: 4px;
  box-shadow: 0px 2px 10px lightgrey;
  display: flex;
  margin-bottom: 30px;

  // Get rid of a styles
  text-decoration: none;
  color: inherit;
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    box-shadow: 2px 6px 30px 3px lightgrey;
    background-color: #f9f9f9;
  }
}

.image {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  object-fit: cover;
  height: 200px;
  width: 250px;
}

.infoContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 25px;
}

.textTitle {
  font-family: "ProximaNovaRegular";
  font-size: 22px;
}

.label {
  font-family: "ProximaNovaRegular";
  font-size: 14px;
  background: $color-brand-main;
  color: white;
  align-self: flex-start;
  padding: 2px 10px;
  border-radius: 5px;
  margin-top: 1em;
}
