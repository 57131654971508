@import "../../../Themes/global";

.container {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: $color-white;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 32px);
  padding-top: 2px;
  overflow: auto;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 56px;
  padding-top: 24px;
  background: $color-white;
}

.actions {
  display: flex;
  align-items: center;
}

.main {
  padding-top: 36px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 100%;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  @extend %text-headline-3;
  color: $color-brand-dark;
}

.imageWrapper {
  width: 128px;
  height: 128px;
  min-height: 128px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px $color-black-20;
  overflow: hidden;

  & > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.info {
  display: flex;
  flex-direction: column;
  padding: 8px 0 24px;
}

.infoLine {
  display: flex;
  @extend %text-footnote;
  color: $color-black-50;
}

.reports {
  width: 100%;
  padding: 0 0 24px;
  flex: 1 0 auto;
}

.reportsEmpty {
  width: 100%;
  color: $color-fontGray;
}

.reportsList {
  padding: 14px 0;
  width: 100%;
}

.reportListItem {
  width: 100%;
  padding: 16px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  border: 1px solid $color-grayish;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:not(:last-child) {
    border-bottom: none;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background: $color-grayish;
  }
}

.reportLinkIcon {
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 24px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: rgba(0, 0, 0, 0.54);
}

.reportLinkText {
  cursor: pointer;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
}
