.infoBoxContainer {
  background: black;
  border-radius: 7px;
  padding: 25px;
  position: absolute;
  right: 0px;
  top: 30px;
  display: flex;
  justify-content: space-evenly;
}

.infoBoxColumn {
  min-height: 350px;
  &:first-child {
    flex-grow: 1;
  }
}

.infoBoxRow {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  color: white;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 17px;
  min-width: 280px;

  // &:last-child {
  //   border-bottom: none;
  // }
}

.closeInfoBoxButton {
  position: absolute;
  top: 6px;
  right: 6px;
  color: white;
  cursor: pointer;
}
