.lineChart {
  border: 1px solid lightgray;
  & .axis line,
  & .axis.xaxis path {
    fill: none;
    stroke: lightgray;
    shape-rendering: crispEdges;
    stroke-opacity: 0.6;
  }
  & .tick text {
    font: 10px sans-serif;
    fill: darkgray;
    stroke-opacity: 0.1;
  }
  & .axis.yaxis path {
    fill: none;
    stroke: lightgray;
    stroke-opacity: 0;
  }
}

.flexible {
  width: 100%;
  height: 100%;
}
