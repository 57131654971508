@import "../../../../Themes/global";

/** Phone */
@media only screen and (max-width: $media-breakpoint-desktop-min) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;

    > div {
      margin-bottom: 10px;
    }

    .homeTeam {
      > :nth-child(1) {
        margin-bottom: 10px;
        max-width: 370px;
      }
    }

    .awayTeam {
      > :nth-child(1) {
        margin-bottom: 10px;
        max-width: 370px;
      }
    }

    .topDistances {
      max-width: 370px;
      margin-bottom: 10px;
      
      :nth-child(1) {
        margin-bottom: 10px;
      }
    }
    
    .lastUpdate {
      max-width: 370px;
      margin-bottom: 10px;
    }

    .possessionChart {
      max-width: 370px;
      margin-bottom: 10px;
    }
  }
}

/** Small Desktop */
@media only screen and (min-width: $media-breakpoint-desktop-min) {
  .container {
    width: 100%;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas:  "column-one column-two column-three" 
                          "column-one column-two column-three"
                          "column-one column-two column-three"
                          "column-one column-two column-three"
                          "column-one column-two column-three";
    
    .homeTeam {
      grid-area: column-one;
      display: flex;
      flex-direction: column;
    
      > div {
        margin-bottom: 10px;
      }
    }
    
    .awayTeam {
      grid-area: column-two;
      display: flex;
      flex-direction: column;
    
      > div {
        margin-bottom: 10px;
      }
    }
    
    .columnThree {
      grid-area: column-three;
      max-width: 500px;
    
      > div {
        margin-bottom: 10px;
      }
    }
    
    .topDistances {
      grid-area: top-distances;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    
      :nth-child(1) {
        margin-bottom: 10px;
      }
    }
    
    .lastUpdate {
      grid-area: top-right;
    }
    
    .possessionChart {
      grid-area: possession;
    }
  }
}

