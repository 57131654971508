@import "../../Themes/global";

.menuOption {
  font-size: 14px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;

  &:hover {
    background-color: rgba($color-brand-main, 0.1) !important;
  }
}
