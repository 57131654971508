.form {
  display: flex;
  padding: 20px 36px 8px;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}

.formLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.formImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  position: relative;
  border-radius: 4px;
  height: 154px;
  width: 154px;
}

.image {
  border-radius: 4px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.details {
  padding-left: 24px;
  height: 154px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.firstName {
  flex: 0 0 50%;
  padding-right: 8px;
}

.lastName {
  flex: 0 0 50%;
  padding-left: 8px;
}

.num {
  flex: 0 0 30%;
  padding-right: 8px;
}

.position {
  flex: 0 0 70%;
  padding-left: 8px;
}
