@import "../../Themes/global";

.externalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
}

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  box-shadow: 0px 0px 4px $color-black-20;

  &:hover {
    box-shadow: 0px 0px 8px $color-black-20;
  }
}

.imageWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.fileInput {
  visibility: hidden;
}

.error {
  padding: 6px;
  font-size: 14px;
  color: red;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  text-align: center;
}

.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.placeholder {
  color: $color-gray;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.placeholderText {
  margin-left: 8px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  background: $color-black-50;
  display: none;
  border-radius: 4px;
  z-index: 10;
  color: $color-white;
}

.overlay.active {
  display: flex;
}
